import { Swipe, SwipeItem, Tabs, Tab, Sticky, Tag, Overlay, Popup, Icon, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
import JSSdk from '../../common/JSSdk.vue'
import Loading from '../../common/Loading.vue'
import Login from '../../intercept/Login.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
import Call from '../../common/Call.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Navbar: Navbar,
        JSSdk: JSSdk,
        Loading: Loading,
        Login: Login,
        LogVisit: LogVisit,
        Clue: Clue,
        Call: Call,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Sticky.name]: Sticky,
        [Tag.name]: Tag,
        [Overlay.name]: Overlay,
        [Popup.name]: Popup,
        [Icon.name]: Icon,
        [Image.name]: Image
    },
    data () {
        return {
            taskCode: '',
            applyCount: 0,
            operator: { name: '', address: '', linkPerson: '', linkPhone: '', linkWechat: '', type: '' },
            loadingShow: false,
            shareShow: false,
            role: 'CLT',
            personCode: '',
            task: {},
            wallet: {},
            consult: { amount: 0, type: 'DOC', endTime: '' },
            payOrderCode: ''
        }
    },
    mounted () {
        var query = this.$route.query
        this.taskCode = query.taskCode
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.retrieveTask()
            this.retrieveWallet()
            this.$refs.logVisit.createLog()
            var res = this.$refs.jssdk.init(['SHARE'])
            if (res) {
                this.initShare()
            }
        },
        initShare () {
            var title = this.task.name
            var desc = this.task.description
            var link = 'https://bms.utp.ink/mde/hire/detail?taskCode=' + this.taskCode
            if (this.role === 'SEL') {
                link = link + '&refereeType=SEL'
            }
            if (this.role === 'CLT') {
                link = link + '&refereeType=CLT'
            }
            var logo = 'https://bms.utp.ink/static/logo_lha.png'
            this.$refs.jssdk.initShare(title, desc, link, logo)
        },
        async retrieveWallet () {
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/retrieveWallet')
            if (res.status === '200') {
                this.wallet = res.data
            }
        },
        async retrieveTask () {
            var pd = { code: this.taskCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/retrieveTask', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.task = res.data
                this.retrieveTaskCondition()
                this.initShare()
            }
        },
        async retrieveTaskCondition () {
            var pd = { taskCode: this.taskCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/retrieveTaskConditionList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.conditionList = res.data
            }
        },
        async operateHandle (type) {
            if (this.task.buy === 'Y') {
                if (type === 'link') {
                    this.telphone()
                } else if (type === 'signup') {
                    this.signup()
                }
            } else {
                this.$dialog.confirm({
                    title: '提示',
                    message: '此次操作扣取10个金豆，确认继续进行吗？'
                })
                    .then(() => {
                        // on confirm
                        this.buy(type)
                    })
            }
        },
        async buy (type) {
            var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
            var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
            var referee = '166' // window.sessionStorage.getItem(this.SESSION_REFEREE)
            var app = window.sessionStorage.getItem(this.SESSION_APP)
            var pd = { taskCode: this.taskCode, amount: 10, operator: operator, seller: seller, referee: referee, app: app }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/buy', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.task.buy = 'Y'
                this.retrieveWallet()
                if (type === 'link') {
                    this.telphone()
                } else if (type === 'signup') {
                    this.signup()
                }
            }
        },
        async signup () {
            var app = window.sessionStorage.getItem(this.SESSION_APP)
            var pd = { taskCode: this.taskCode, app: app }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/signup', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '报名成功'
                }).then(() => {
                    this.back()
                })
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: res.msg
                })
            }
        },
        async telphone () {
            var pd = { code: this.task.createUser }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveUser', this.$qs.stringify(pd))
            if (res.status === '200') {
                window.location.href = 'tel:' + res.data.phone
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '查询雇主信息失败'
                })
            }
        },
        showWechat () {
            this.wechatShow = true
        },
        shareImage () {
            this.$router.push({ path: '/mde/recruit/shareImage', query: { taskCode: this.taskCode } })
        },
        home () {
            this.$router.push({ path: '/mde/hire/home' })
        },
        charge () {
            this.$router.push({ path: '/mde/hire/recharge', query: { type: 'MERCHANT', group: 'HIRE_USER' } })
        },
        onlineChat () {
            this.$refs.call.callWinShow('true')
        },
        createViewClue () {
            var param = { taskCode: this.taskCode, url: window.location.href }
            var content = '补岗招聘浏览'
            this.$refs.clue.createClue('RECRUIT_VIEW_RECRUIT', this.taskCode, JSON.stringify(param), content)
        },
        createOrderClue (orderCode) {
            var param = { taskCode: this.taskCode, personCode: this.personCode, orderCode: orderCode, url: window.location.href }
            var content = '补岗招聘订单'
            this.$refs.clue.createClue('RECRUIT_ORDER_RECRUIT', orderCode, JSON.stringify(param), content)
        },
        shareTask () {
            this.shareShow = true
        },
        applySeller () {
            this.$router.push({ path: '/seller/register' })
        },
        back () {
            var backurl = window.sessionStorage.getItem(window.const.global.HIRE_DETAIL_INDEX_BACK)
            if (backurl !== undefined && backurl !== null) {
                window.location.href = backurl
            } else {
                this.$router.push({ path: '/mde/hire/home' })
            }
        }
    }
}
