<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <Navbar title="职位咨询" :callback="back" type="ALL" />
    <van-swipe class="top-img" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          :src="require('../../../../assets/images/integrate/3.jpg')"
          class="img"
      /></van-swipe-item>
    </van-swipe>
    <van-row class="simple">
      <van-col span="24">
        <van-row class="name">
          <van-col span="24">{{ task.name }}</van-col>
        </van-row>
        <van-row class="label">
          <van-col span="6"
            ><van-tag mark :color="COLOR_M">招聘优势</van-tag></van-col
          >
          <van-col span="18">
            <span class="color-s1">{{ task.advantage }}</span>
          </van-col>
        </van-row>
        <van-row class="salary">
          <van-col span="6"
            ><van-tag mark :color="COLOR_M">薪资福利</van-tag></van-col
          >
          <van-col span="18" class="fc-orange">
            <span v-if="task.salaryMin > 0">{{ task.salaryMin }}</span
            ><span v-if="task.salaryMax > 0">-{{ task.salaryMax }}</span
            >元/<span v-if="task.salaryType === 'YER'">年</span>
            <span v-if="task.salaryType === 'MON'">月</span>
            <span v-if="task.salaryType === 'DAY'">天</span>
            <span v-if="task.salaryType === 'TIM'">小时</span>
            <span v-if="task.salaryType === 'MET'">米</span>
            <span v-if="task.salaryType === 'SMT'">平米</span></van-col
          >
        </van-row>
        <van-row class="amount">
          <van-col span="6"
            ><van-tag mark :color="COLOR_M">招聘人数</van-tag></van-col
          >
          <van-col span="18">{{ task.count }}人</van-col>
        </van-row>
        <van-row class="time">
          <van-col span="6"
            ><van-tag mark :color="COLOR_M">发布日期</van-tag></van-col
          >
          <van-col span="18">{{ task.createTime }}</van-col>
        </van-row>
        <div class="hr"></div>
        <van-row class="bean">
          <van-col span="20" class="price"
            >分享收益：<span class="number color-s2">0.1元/次</span
            >(分享用户购买可得奖励)</van-col
          ><van-col span="4"
            ><van-button
              icon="share-o"
              :color="COLOR_S1"
              class="btn"
              size="mini"
              @click="shareTask()"
              >分享</van-button
            ></van-col
          > </van-row
        ><van-row class="bean">
          <van-col span="20" class="price"
            >微客收益：<span class="number color-s2">0.5元/次</span
            >(分享用户购买可得奖励)</van-col
          ><van-col span="4"
            ><van-button
              icon="plus"
              :color="COLOR_S2"
              class="btn"
              size="mini"
              @click="applySeller()"
              >申请</van-button
            ></van-col
          > </van-row
        ><van-row class="bean">
          <van-col span="20" class="price"
            >金豆余额：<span class="number color-s2"
              >{{ wallet.beanBalance }}金豆</span
            ><span class="color-s2"></span></van-col
          ><van-col span="4"
            ><van-button
              icon="gold-coin-o"
              :color="COLOR_M"
              class="btn"
              size="mini"
              @click="charge()"
              >充值</van-button
            ></van-col
          > </van-row
        ><van-row class="bean">
          <van-col span="24" class="price">
            <div v-if="task.buy === 'Y'">
              购买状态：<van-tag :color="COLOR_M">已购买</van-tag
              ><span class="color-s2">(操作消耗0个金豆)</span>
            </div>
            <div v-if="task.buy === 'N'">
              购买状态：<van-tag :color="COLOR_S1">未购买</van-tag
              ><span class="color-s2">(操作消耗10个金豆)</span>
            </div>
          </van-col>
        </van-row>
        <van-row class="operate">
          <van-col span="24">
            <van-button
              icon="phone-o"
              :color="COLOR_M"
              size="small"
              @click="operateHandle('link')"
              >联系雇主</van-button
            >&nbsp;&nbsp;&nbsp;&nbsp;<van-button
              icon="sign"
              :color="COLOR_S1"
              size="small"
              @click="operateHandle('signup')"
              >立即报名</van-button
            ></van-col
          >
        </van-row>
        <van-row class="tips color-s2">
          <van-col span="4">
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-notice"></use></svg
          ></van-col>
          <van-col span="20" class="text">多次操作不重复扣取金豆</van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-row @click="home()">
      <van-col span="24">
        <van-image
          class="focus-img"
          :src="require('../../../../assets/images/recruit/wp-focus.jpg')"
        ></van-image>
      </van-col>
    </van-row>
    <div class="detail">
      <van-tabs :active="activeName" :color="COLOR_M" :border="true">
        <van-tab title="工作描述" name="description">
          <div class="pre-text ta-left content" v-html="task.description"></div>
        </van-tab>
        <van-tab title="条件要求" name="welfare">
          <van-row
            v-for="condition in conditionList"
            :key="condition.key"
            class="condition"
          >
            <van-col span="8" class="name"
              ><van-tag mark :color="COLOR_M">{{
                condition.name
              }}</van-tag></van-col
            >
            <van-col span="16" class="text">{{ condition.text }}</van-col>
          </van-row>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Clue ref="clue" />
    <Call
      ref="call"
      win-show="false"
      entry-show="false"
      clue-model="CERT_CONSULT_ONLINE"
    />
    <JSSdk ref="jssdk" />
    <LogVisit
      ref="logVisit"
      module-type="HRE"
      module-label="HRE_DETAIL_INDEX"
    />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
